import { Components, Theme, ThemeOptions, createTheme } from '@mui/material';

type MuiComponents = Components<Omit<Theme, 'components'>>;

const themeComponents: MuiComponents = {};

const themeOptions: ThemeOptions = {
  components: themeComponents
};

export const muiTheme = createTheme(themeOptions);
