import { Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

export function HomePage() {
  return (
    <Box>
      <Link to="/thumbnail-grabber">
        <Typography>Thumbnail Grabber</Typography>
      </Link>
      <Link to="/terraria-checklist">
        <Typography>Terraria Checklist</Typography>
      </Link>
    </Box>
  );
}
