import { Box, TextField } from '@mui/material';
import { useState } from 'react';

export function TerrariaChecklist() {
  const [fileArrayBuffer, setFileArrayBuffer] = useState<ArrayBuffer>(new ArrayBuffer(0));

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>): void {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          setFileArrayBuffer(e.target.result as ArrayBuffer);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  return (
    <div>
      <TextField
        type="file"
        onChange={handleFileChange}
        InputLabelProps={{
          shrink: true
        }}
        variant="outlined"
      />
      {fileArrayBuffer && (
        <div>
          <p>File loaded: {fileArrayBuffer.byteLength} bytes</p>
          {}
        </div>
      )}
    </div>
  );
}
