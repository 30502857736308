import { Box, ThemeProvider, Typography } from '@mui/material';

import './App.scss';
import { muiTheme } from './theme';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { HomePage } from './components/home-page/HomePage';
import { ThumbnailGrabber } from './components/thumbnail-grabber/ThumbnailGrabber';
import { TerrariaChecklist } from './components/terraria-checklist/TerrariaChecklist';

export function App() {
  return (
    <ThemeProvider theme={muiTheme}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/thumbnail-grabber" element={<ThumbnailGrabber />} />
          <Route path="/terraria-checklist" element={<TerrariaChecklist />} />
        </Routes>
      </HashRouter>
    </ThemeProvider>
  );
}
