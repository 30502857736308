import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

function extractYouTubeID(url: string): string {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[2].length == 11) {
    return match[2];
  }

  return '';
}

const THUMBNAIL_PREFIX = 'https://i.ytimg.com/vi';

export function ThumbnailGrabber() {
  const [videoID, setVideoID] = useState<string>();
  const [rawInputID, setRawInputID] = useState<string>();

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    setRawInputID(event.target.value);
  }

  function handleClick(): void {
    const extractedID = extractYouTubeID(rawInputID ?? '');
    setVideoID(extractedID);
  }

  return (
    <Box>
      <TextField onChange={handleChange} />
      <Button onClick={handleClick}>Download</Button>
      <Stack direction="column" spacing="20px">
        <Typography>120 x 90</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/1.jpg`} width="120px" height="90px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/2.jpg`} width="120px" height="90px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/3.jpg`} width="120px" height="90px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/default.jpg`} width="120px" height="90px" />
        </Stack>
        <Typography>320 x 180</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/mq1.jpg`} width="320px" height="180px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/mq2.jpg`} width="320px" height="180px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/mq3.jpg`} width="320px" height="180px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/mqdefault.jpg`} width="320px" height="180px" />
        </Stack>
        <Typography>480 x 360</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/0.jpg`} width="480px" height="360px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/hq1.jpg`} width="480px" height="360px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/hq2.jpg`} width="480px" height="360px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/hq3.jpg`} width="480px" height="360px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/hqdefault.jpg`} width="480px" height="360px" />
        </Stack>
        <Typography>640 x 480</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/sd1.jpg`} width="640px" height="480px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/sd2.jpg`} width="640px" height="480px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/sd3.jpg`} width="640px" height="480px" />
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/sddefault.jpg`} width="640px" height="480px" />
        </Stack>
        <Typography>1280 x 720</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/hq720.jpg`} width="1280px" height="720px" />
        </Stack>
        <Typography>1920 x 1080</Typography>
        <Stack direction="row" spacing="20px">
          <img src={`${THUMBNAIL_PREFIX}/${videoID}/maxresdefault.jpg`} width="1920px" height="1080px" />
        </Stack>
      </Stack>
    </Box>
  );
}
